<template>
	<div class="empty">
	</div>
</template>

<script>
export default {
	mounted() {
		this.$store.commit('loading/SET_TRUE');
		setTimeout(this.goToLink, 1000)
	},

	methods: {
		goToLink() {
			let href = "https://bit.ly/럭스테이플레이스토어"

			if(this.$store.state.config.ios){
				href = "https://bit.ly/럭스테이앱스토어"
			}

			return location.href = href;
		}
	}
}
</script>

<style lang="scss" scoped>
	.empty {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #ffffff;
		z-index: 1500;
	}
</style>